import { Group, Text, Tooltip } from '@mantine/core';

import BaseResult from '../../search/components/BaseResult';
import LocationIcon from '../../iconmap/LocationIcon';
import IconMap from '../../iconmap/IconMap';
import List from '@/components/content/list/List';
import IssueWrapperV2 from '../../issue/components/IssueWrapper';

import { formatIsoDate } from '@/utils/date';
import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';

import { objectHasValues } from '@/utils';
import { FLAG_ICONS } from '../../iconmap/icons/flags';
import { parseMetisString } from '@/utils/metisUtils';
import { getContextData } from '../../catalogs/utils/context';
import { INDUSTRY_ICON_MAP } from '../../iconmap/icons/industry';

import type { FinishedIntelTicket } from '../types';
import { finishedIntelDrawer } from '../configs/finished.drawer';

const tracker = mercuryTrackers[MercuryTrackersIds.finished];

const FinishedSearchResults = ({ data }: { data: FinishedIntelTicket }) => {
  const flags = [
    ['analyst_note', data.analysts_note !== null],
    ['iocs', data.iocs !== null && data.iocs.length > 0],
  ].filter(([, value]) => value);
  const context = getContextData(data, ['threat_actors', 'malware_kits', 'vulnerabilities']);

  return (
    <BaseResult
      title={{ id: data.id, name: data.name }}
      content={data.description}
      tracker={tracker}
      drawerContent={(
        <IssueWrapperV2
          top={finishedIntelDrawer}
          data={data}
          id={data.id}
          removePadding
        />
      )}
      footer={[
        data.created_at && (
        <Text size="sm">
          <Text fw="bold" span>Date:</Text> {formatIsoDate(data.created_at)}
        </Text>
        ),
        data.locations && !Array.isArray(data.locations) && objectHasValues(data.locations)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Locations:</Text>
            <LocationIcon locations={data.locations} id={data.id} max={2} layout="small" />
          </Group>
        ),
        data.industries && !Array.isArray(data.industries) && objectHasValues(data.industries)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Industries:</Text>
            <IconMap
              id={data.id}
              map={data.industries}
              icons={INDUSTRY_ICON_MAP}
              showTooltip
              layout="small"
              renderLabelWithoutIcon={(label) => label}
              max={3}
            />
          </Group>
        ),
        flags.length > 0
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Additional Flags:</Text>
            <IconMap
              id={data.id}
              icons={FLAG_ICONS}
              map={Object.fromEntries(flags.map(([key]) => [key, key]))}
              tooltip={(t) => `Contains ${parseMetisString(t)}`}
              layout="small"
              showTooltip
            />
          </Group>
        ),
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip label={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default FinishedSearchResults;
