import { Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';

import BaseResult from '../../search/components/BaseResult';
import Pill from '@/components/content/pills/Pill';

import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';
import { formatIsoDate } from '@/utils/date';

import type { RFITIcket } from '../types';

const ServiceRequestsSearchResult = ({ data }: { data: RFITIcket }) => (
  <BaseResult
    title={{ id: data.id, name: data.name }}
    content={data.description}
    tracker={mercuryTrackers[MercuryTrackersIds.rfi]}
    footer={[
      data.created_at && (
      <Text size="sm">
        <Text fw="bold" span>Date:</Text> {formatIsoDate(data.created_at)}
      </Text>
      ),
      <Text size="sm">
        <Text fw="bold" span>Status:</Text> <Pill value={upperFirst(data.status)} component="span" />
      </Text>,
    ]}
  />
);

export default ServiceRequestsSearchResult;
