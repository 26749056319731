import type { ContextVariables, FetchDataArguments } from '../types/api';
import { SERVER_ERROR } from '@/constants/text/english';

export const HOST = '/api';

class FetchError extends Error {
  constructor(headers: Headers, { error: { message, code, details } }: any = {}, errorMessage?: string | boolean) {
    super(errorMessage || message);
    Object.assign(this, { code, details, headers });
  }
}

const getBuiltError = (code: number, message: string) => ({ error: { code, message } });

const addContext = (endpoint: string, context?: ContextVariables) => {
  if (!context || !context.source) return endpoint;

  const [url, queryParams] = endpoint.split('?');

  const urlSearchParams = new URLSearchParams(queryParams);

  urlSearchParams.set('ctx_source', context.source);

  return `${url}?${urlSearchParams.toString()}`;
};

export const fetchData = async ({
  endpoint,
  options = { headers: {} },
  error = SERVER_ERROR,
  noContent = 'No data available',
  unauthorized = SERVER_ERROR,
  requestLimit = SERVER_ERROR,
  useResponseError = false,
  resolveOnNoContent = false,
  useCompleteEndpoint,
  context,
}: FetchDataArguments, signal?: AbortSignal) => {
  const url = !useCompleteEndpoint ? `${HOST}/${endpoint}` : endpoint;
  const endpointWithContext = addContext(url, context);
  const response = await fetch(endpointWithContext, { credentials: 'include', signal, ...options });

  let json;

  if (response.status >= 500) {
    throw new FetchError(response.headers, getBuiltError(
      response.status,
      SERVER_ERROR,
    ));
  }

  if (response.status === 401) {
    throw new FetchError(response.headers, getBuiltError(
      response.status,
      unauthorized,
    ));
  }

  if (response.status === 429) {
    throw new FetchError(response.headers, getBuiltError(
      response.status,
      requestLimit,
    ));
  }

  if (!response.ok) {
    const text = await response.text();

    if (!text) {
      throw new FetchError(response.headers, getBuiltError(
        response.status,
        error,
      ));
    }

    // Throw a fetch error with an optional custom error.
    throw new FetchError(response.headers, JSON.parse(text), useResponseError ? false : error);
  }

  if (response.status === 204) {
    if (resolveOnNoContent) {
      return response;
    }

    throw new FetchError(response.headers, getBuiltError(response.status, noContent));
  }

  if (!useCompleteEndpoint) {
    try {
      if (response.headers.get('content-type')?.includes('application/json')) {
        json = await response.json();
      } else {
        json = await response.text();
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }

    if (json) {
      return json;
    }
  }

  return response;
};
