import {
  type ReactNode, type MouseEvent, useEffect, Ref, forwardRef,
} from 'react';
import Linkv2 from '@/components/content/link/Linkv2';

import { useDrawer } from '@/hooks/useDrawer';

interface DrawerLinkProps {
  id: string | number;
  title?: string;
  value: string;
  initialOpen?: boolean;
  content: ReactNode;
  link?: ReactNode;
  url?: string;
  renderButton?: (props: { toggleDrawer: VoidFunction, active: boolean }) => ReactNode;
  noPrint?: boolean;
}

const DrawerLink = forwardRef((
  {
    id,
    title,
    url,
    link,
    value,
    content,
    initialOpen = false,
    renderButton,
    noPrint,
  }: DrawerLinkProps,
  ref: Ref<HTMLAnchorElement>,
) => {
  const { openDrawer, drawerid } = useDrawer();

  const toggle = () => {
    openDrawer({
      drawerid: id,
      children: content,
      title,
      link,
    });
  };

  const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    toggle();
  };

  /**
   * This useEffect is used to display an animation when the drawer is initially open
   */
  useEffect(() => {
    if (initialOpen) {
      toggle();
    }
  }, []);

  return (
    <>
      {renderButton ? (
        renderButton({ toggleDrawer: toggle, active: drawerid === id })
      ) : (
        <Linkv2
          onClick={clickHandler}
          highlight={drawerid === id}
          href={url}
          ref={ref}
          noPrint={noPrint}
        >
          {value}
        </Linkv2>
      )}
    </>
  );
});

export default DrawerLink;
