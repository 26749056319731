import createClient, { InitParam, MaybeOptionalInit } from 'openapi-fetch';

import { fetchData } from './fetch';
import type { paths } from '../../unifiedapi'; // generated by openapi-typescript
import { UnifiedApiMethodsResponse, UnifiedApiPaths } from '@/types/mercury-data-types/unifiedapi';

export const openapiClient = createClient<paths>({
  baseUrl: '/api/v0',
  fetch: (input) => {
    const request = input.clone();
    const headers = new Headers(request.headers);
    const source = headers.get('source');

    return fetchData({
      endpoint: request.url,
      useCompleteEndpoint: true,
      context: { source },
      options: {
        method: request.method,
        body: request.body,
        headers: request.headers,
      },
    }, input.signal);
  },
});

export const openApiFetch = async <TPath extends UnifiedApiPaths>(
  path: TPath,
  ...init: InitParam<MaybeOptionalInit<paths[TPath], 'get'>>
) => {
  const response = await openapiClient.GET(path, ...init);

  if (response.error) throw new Error(JSON.stringify(response.error));

  return response.data as UnifiedApiMethodsResponse<TPath>;
};
