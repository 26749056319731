import { Group, Text, Tooltip } from '@mantine/core';

import BaseResult from '../../search/components/BaseResult';
import List from '@/components/content/list/List';
import IconMap from '../../iconmap/IconMap';
import IncidentModal from './IncidentModal';

import { formatIsoDate } from '@/utils/date';
import { parseMetisString } from '@/utils/metisUtils';
import { getContextData } from '../../catalogs/utils/context';
import { objectHasValues } from '@/utils';

import { FLAG_ICONS } from '../../iconmap/icons/flags';
import { INCIDENT_CATEGORY_ICONS } from '../../iconmap/icons/incidents_category';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import type { IncidentListRecord } from '../types';

const IncidentSearchResult = ({ data }: { data: IncidentListRecord }) => {
  const flags = [
    ['takedowns', data.takedowns && objectHasValues(data.takedowns)],
    ['iocs', data.iocs !== null && data.iocs.length > 0],
  ].filter(([, value]) => value);

  const context = getContextData(data, ['threat_actors', 'malware_kits', 'vulnerabilities']);

  return (
    <BaseResult
      title={{ id: data.id, name: data.name }}
      content={data.description}
      tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
      customLink={(
        <IncidentModal
          incident={data}
          inSearchResults
        />
      )}
      footer={[
        data.created_at && (
          <Text size="sm">
            <Text fw="bold" span>Date:</Text> {formatIsoDate(data.created_at)}
          </Text>
        ),
        data.subcategory && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Category:</Text>
            <IconMap
              id={data.id}
              map={{ [data.subcategory]: data.subcategory }}
              icons={INCIDENT_CATEGORY_ICONS}
              layout="small"
              showTooltip
            />
          </Group>
        ),
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip label={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
        flags.length > 0
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Additional Flags:</Text>
            <IconMap
              id={data.id}
              icons={FLAG_ICONS}
              map={Object.fromEntries(flags.map(([key]) => [key, key]))}
              tooltip={(t) => `Contains ${parseMetisString(t)}`}
              layout="small"
              showTooltip
            />
          </Group>
        ),
      ]}
    />
  );
};

export default IncidentSearchResult;
