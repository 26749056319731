import { Stack, Text } from '@mantine/core';

import Markdown from '@/components/content/markdown/Markdown';
import InformationReliability from '@/components/content/informationreliability/InformationReliability';

import { createHelper } from '@/client/features/issue/utils/helper';

import type { MetisSource } from '../types';
import { formatIsoDate } from '@/utils/date';
import { getValues } from '@/utils';

const helper = createHelper<MetisSource>();

export const sourcesDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Source</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Source Information',
    submodules: [
      helper.accessor((data) => data.type, {
        title: 'Type',
      }),
      helper.accessor((data) => data.url, {
        title: 'URL',
      }),
      helper.accessor((data) => data.reliability, {
        title: 'Source Reliability Score',
        element: ({ getValue }) => (
          <InformationReliability value={getValue()} />
        ),
      }),
      helper.accessor((data) => getValues(data.companies), {
        title: 'Company Related',
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Last Updated',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
    ],
  },
];
